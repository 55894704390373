import { amountToNumber, rateToNumber, type Amount } from '@orus.eu/amount'
import type { CustomerContractDescription } from '@orus.eu/backend/src/views/customer-contract-view'
import {
  getCommonClientAttributes,
  getDiscountCodeForSubscriptionFunnel,
  getDiscountRateForSubscriptionFunnel,
  getEffectiveYearlyTotalPremium,
  type AggregatedQuote,
} from '@orus.eu/dimensions'
import { sleep } from '@orus.eu/sleep'
import { useSearch } from '@tanstack/react-router'
import { useMemo } from 'react'
import { trpc } from '../../../../client'
import { assert } from '../../../../lib/errors'
import { logger } from '../../../../lib/logger'
import { sessionManager } from '../../../../lib/session'
import { sendMessage } from '../../../../lib/tracking/tracking'

export type CheckoutPagePhase =
  | {
      id: 'review'
    }
  | {
      id: 'validation'
      token: string
    }

export function useCheckoutPagePhase(): CheckoutPagePhase {
  const searchParams = useSearch({ strict: false })
  const isValidationStep = !!searchParams.validate
  const token = searchParams.token

  return useMemo<CheckoutPagePhase>(() => {
    if (isValidationStep) {
      assert(token, 'Expected to have a token in the url when in validation step')
      return {
        id: 'validation',
        token,
      }
    }

    return { id: 'review' }
  }, [isValidationStep, token])
}

export async function waitForContractToBeReady(subscriptionId: string): Promise<void> {
  let contract: null | CustomerContractDescription = null
  while (!contract) {
    try {
      contract = await trpc.subscriptions.checkContractAndUserCreated.query(subscriptionId)
    } catch (_err) {
      logger.event('retrying_checkContractAndUserCreated_after_error')
    }
    if (!contract) {
      await sleep(1000)
    }
  }

  const yearlyTotalPremium = getYearlyTotalPremium(contract.quote.quote)
  const yearlyTotalPremiumEuros = amountToNumber(yearlyTotalPremium)
  const discountRate = getDiscountRateForSubscriptionFunnel(contract.discount)
  sendMessage({
    event: 'contract_signed_front',
    subscription_id: contract.subscriptionId,
    activity: contract.activity.displayName,
    discount_code: getDiscountCodeForSubscriptionFunnel(contract.discount) ?? null,
    discount_rate: discountRate ? rateToNumber(discountRate) : null,
    page_category: 'checkout',
    yearly_total_premium: yearlyTotalPremiumEuros,
    contract_number: contract.contractNumber,
    policy_type: contract.riskCarrierProducts.join(','),
    ...getCommonClientAttributes(contract.riskCarrierProducts),
  })

  await sessionManager.refreshSession()
}

function getYearlyTotalPremium(quote: AggregatedQuote): Amount {
  return getEffectiveYearlyTotalPremium(quote)
}

import { css } from '@emotion/react'
import type { Amount, FinancialRate } from '@orus.eu/amount'
import type { CalendarDate } from '@orus.eu/calendar-date'
import {
  type AggregatedQuote,
  type Commitment,
  type ProductAttributes,
  type StaticFilesGroup,
} from '@orus.eu/dimensions'
import type { OperatingZone } from '@orus.eu/operating-zone'
import {
  ContractBannerV2,
  Documents,
  spacing,
  Text,
  useScreenType,
  useTranslate,
  useUrlFileBytes,
  type DocumentGroup,
} from '@orus.eu/pharaoh'
import type { ReactElement } from 'react'
import { memo, useMemo } from 'react'
import {
  getAgreedTermsDraftUrl,
  getCurrentSubscriptionGeneralTermsDocumentDownloadLink,
  getUserAgreedTermsDraftUrl,
} from '../../../../lib/download-links'
import { usePermissions } from '../../../../lib/use-permissions'
import { useShowIllustrations } from '../../../../lib/use-show-illustrations'

const SharedSubscriptionContractElement: React.FunctionComponent<{
  subscriptionId: string
  contractFileId: string
  generalTermsGroups: StaticFilesGroup[]
  quote: AggregatedQuote
  startDate: CalendarDate
  productsAttributes: ProductAttributes[]
  forbiddenMonthlyPayment: boolean
  commitment: Commitment
  discount: FinancialRate
  yearlyCommitmentHasDiscount: boolean
  historyTakeoverPrice: Amount | undefined
  partnerApplicationFee: Amount | undefined
  goBackToQuoteStep?: () => void
  operatingZone: OperatingZone
}> = memo(function SharedSubscriptionContractElement({
  subscriptionId,
  contractFileId,
  generalTermsGroups,
  quote,
  startDate,
  productsAttributes,
  commitment,
  forbiddenMonthlyPayment,
  discount,
  yearlyCommitmentHasDiscount,
  goBackToQuoteStep,
  historyTakeoverPrice,
  partnerApplicationFee,
  operatingZone,
}): ReactElement {
  const translate = useTranslate()
  const screenType = useScreenType()
  const isPlatform = usePermissions().type === 'platform'
  const isPartner = usePermissions().type === 'partner'
  const showIllustrations = useShowIllustrations()

  const documentGroups: DocumentGroup[] = useMemo(() => {
    return generalTermsGroups.map((group) => {
      return {
        documents: group.documents.map((d) => {
          return {
            url: getCurrentSubscriptionGeneralTermsDocumentDownloadLink({
              subscriptionId,
              file: d,
            }),
            fileName: d.fileName,
          }
        }),
        hint: group.hint,
        id: group.id,
      }
    }) as DocumentGroup[]
  }, [generalTermsGroups, subscriptionId])

  const { data: contractFile } = useUrlFileBytes(`/download/${contractFileId}/`, { autoLoad: true })
  const { data: contractDraftFile } = useUrlFileBytes(
    isPlatform ? getUserAgreedTermsDraftUrl(subscriptionId) : getAgreedTermsDraftUrl(subscriptionId),
    { autoLoad: true },
  )

  return (
    <div>
      <Text
        variant="h3"
        css={css`
          margin-bottom: ${spacing[70]};
        `}
      >
        {translate('subscription_funnel_checkout_last_step')}
      </Text>
      <ContractBannerV2
        productsAttributes={productsAttributes}
        onEditQuote={goBackToQuoteStep}
        startDate={startDate}
        downloadablePdfBytes={contractDraftFile}
        pdfBytes={contractFile}
        commitment={commitment}
        monthlyPrice={quote.subsequentMonthsTotalPremium}
        paymentRecurrence={quote.paymentRecurrence}
        forbiddenMonthlyPayment={forbiddenMonthlyPayment}
        yearlyPrice={quote.yearlyTotalPremium}
        discount={discount}
        firstPeriodPrice={quote.firstPaymentAmount}
        yearlyCommitmentHasDiscount={yearlyCommitmentHasDiscount}
        yearlyTerrorismTaxPrice={quote.terrorismTax}
        historyTakeoverPrice={historyTakeoverPrice}
        partnerApplicationFee={partnerApplicationFee}
        isPartner={isPartner}
        operatingZone={operatingZone}
        useIllustration={showIllustrations}
      />
      <Text
        variant="subtitle"
        css={css`
          margin-top: ${spacing[70]};
          margin-bottom: ${spacing[50]};
        `}
      >
        {translate('subscription_funnel_checkout_other_documents')}
      </Text>
      <div
        css={css`
          margin-bottom: ${screenType === 'mobile' ? spacing[90] : '0px'};
        `}
      >
        <Documents groups={documentGroups} />
        {operatingZone === 'es' ? (
          <Text
            variant="caption"
            css={css`
              margin-top: ${spacing[70]};
            `}
          >
            La documentación le será enviada vía email. Si además quiere recibirla en formato papel, escriba a
            hola@orus.eu
          </Text>
        ) : undefined}
      </div>
    </div>
  )
})

export default SharedSubscriptionContractElement

import { css } from '@emotion/react'
import { type Amount } from '@orus.eu/amount'
import { type CalendarDate } from '@orus.eu/calendar-date'
import { type RiskCarrierProductType, type StaticFilesGroup } from '@orus.eu/dimensions'
import {
  CheckoutIbanWizard,
  LegacyDialog,
  TabBar,
  spacing,
  stripeElementsZIndex,
  useAsyncCallback,
  useTranslate,
  type CustomerData,
} from '@orus.eu/pharaoh'

import { GenericPaymentMethodSubmitButton } from '@orus.eu/pharaoh'

import { IbanActionButtons } from '@orus.eu/pharaoh/src/legacy/features/iban-wizard/common/IbanActionButtons'
import { isFailure } from '@orus.eu/result'
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { memo, useCallback, useState, type FormEvent, type ReactElement } from 'react'
import { trpc } from '../../../client'
import { useNavigateTo } from '../../../lib/hooks/use-navigate-to-route'
import { useStripeElementsProps } from '../../../lib/stripe/hook'
import { usePermissions } from '../../../lib/use-permissions'
import { ContractSignatureSection } from '../../pages/subscription/checkout/contract-signature-section'
import { PaymentInformationHeader } from '../../pages/subscription/checkout/payment-information-header'
import { useAddStripePaymentMethod } from './add-stripe-payment-method-hook'

type CheckoutProps = {
  paymentAmount: Amount
  dueDate: CalendarDate
  token: string
  subscriptionId: string
  generalTermsGroups: StaticFilesGroup[]
  riskCarrierProducts: RiskCarrierProductType[]
  customerData: CustomerData
  goBackToPreviousStep?: () => void
}

type CheckoutPaymentFormProps = CheckoutProps

const CheckoutPaymentForm = memo<CheckoutPaymentFormProps>(function CheckoutPaymentForm({
  dueDate,
  paymentAmount,
  subscriptionId,
  token,
  generalTermsGroups,
  riskCarrierProducts,
  customerData,
  goBackToPreviousStep,
}): ReactElement {
  const stripeElementsProps = useStripeElementsProps()

  return (
    <Elements {...stripeElementsProps}>
      <CheckoutPaymentElementWrapperForm
        dueDate={dueDate}
        paymentAmount={paymentAmount}
        subscriptionId={subscriptionId}
        token={token}
        generalTermsGroups={generalTermsGroups}
        riskCarrierProducts={riskCarrierProducts}
        customerData={customerData}
        goBackToPreviousStep={goBackToPreviousStep}
      />
    </Elements>
  )
})

export default CheckoutPaymentForm

const CheckoutPaymentElementWrapperForm = memo<CheckoutProps>(function CheckoutPaymentElementWrapperForm({
  dueDate,
  paymentAmount,
  subscriptionId,
  token,
  generalTermsGroups,
  riskCarrierProducts,
  customerData,
  goBackToPreviousStep,
}): ReactElement {
  const translate = useTranslate()
  const stripe = useStripe()
  const { addStripePaymentMethod } = useAddStripePaymentMethod()
  const elements = useElements()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [paymentMean, setPaymentMean] = useState<'sepa' | 'card'>('sepa')
  const [ibanCustomerData, setIbanCustomerData] = useState<CustomerData>(customerData)
  const [iban, setIban] = useState<string | undefined>()
  const [hasAgreedDocuments, setHasAgreedDocuments] = useState<boolean>(false)
  const navigateToOnboarding = useNavigateTo({ to: '/home', hash: 'onboarding' })
  const { type: userType } = usePermissions()

  const onPaymentMeanChange = useCallback((tabId: string) => {
    if (tabId !== 'sepa' && tabId !== 'card') return
    setPaymentMean(tabId)
  }, [])

  const handleSubmit = useAsyncCallback(
    async (event?: FormEvent) => {
      event?.preventDefault()

      if (loading) return

      const returnUrl = buildReturnUrl(token)

      if (paymentMean === 'sepa') {
        if (!iban) return
        const result = await trpc.subscriptions.receiveIban.mutate({ subscriptionId, iban, ...ibanCustomerData })
        if (isFailure(result)) {
          if (result.problem.type === 'subscription-already-finished') {
            if (userType === 'client') {
              navigateToOnboarding()
              return
            } else {
              // For a partner or a platform user, we don't want to redirect to the onboarding page
              window.location.href = returnUrl
              return
            }
          }
          setErrorMessage(translate('sepa_error'))
          return
        }

        window.location.href = returnUrl
        return
      }

      if (!stripe || !elements) {
        return
      }

      addStripePaymentMethod({
        source: { type: 'subscription', subscriptionId },
        stripe,
        elements,
        setErrorMessage,
        setLoading,
        handleSuccess() {
          window.location.href = returnUrl
        },
      })
    },
    [
      loading,
      token,
      paymentMean,
      stripe,
      elements,
      addStripePaymentMethod,
      subscriptionId,
      iban,
      ibanCustomerData,
      translate,
      userType,
      navigateToOnboarding,
    ],
  )
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <PaymentInformationHeader paymentAmount={paymentAmount} dueDate={dueDate} />
        <TabBar
          size="large"
          fullWidth
          tabs={{
            sepa: translate('payment_method_sepa_debit'),
            card: translate('payment_method_card'),
          }}
          selectedTabId={paymentMean}
          onTabChange={onPaymentMeanChange}
          css={css`
            margin-top: ${spacing[70]};
          `}
          padding={'0px'}
        />
        {paymentMean === 'card' ? (
          <div
            css={css`
              margin-top: ${spacing[70]};
              margin-bottom: ${spacing[70]};
              min-height: 345px;
              position: relative;
            `}
          >
            <div
              css={css`
                z-index: ${stripeElementsZIndex};
                position: relative;
              `}
            >
              <PaymentElement />
            </div>
          </div>
        ) : (
          <div
            css={css`
              margin-top: ${spacing[70]};
              margin-bottom: ${spacing[70]};
            `}
          >
            <CheckoutIbanWizard
              initialCustomerData={customerData}
              onValidate={setIbanCustomerData}
              ibanCustomerData={ibanCustomerData}
              iban={iban}
              onIbanChange={setIban}
            />
          </div>
        )}

        <ContractSignatureSection
          subscriptionId={subscriptionId}
          generalTermsGroups={generalTermsGroups}
          riskCarrierProducts={riskCarrierProducts}
          setHasAgreedDocuments={setHasAgreedDocuments}
          hasAgreedDocuments={hasAgreedDocuments}
          actionButtons={
            paymentMean === 'sepa' ? (
              <IbanActionButtons
                isDisabled={!hasAgreedDocuments || !iban}
                onSubmit={handleSubmit}
                goBackToPreviousStep={goBackToPreviousStep}
                isLoading={loading}
                ibanCustomerData={{
                  iban: iban ?? '',
                  firstName: ibanCustomerData.firstName,
                  lastName: ibanCustomerData.lastName,
                }}
              />
            ) : (
              <GenericPaymentMethodSubmitButton
                isLoading={loading}
                isDisabled={!hasAgreedDocuments}
                goBackToPreviousStep={goBackToPreviousStep}
              />
            )
          }
        />
      </form>
      {errorMessage ? (
        <LegacyDialog title={translate('stripe_error_message')} onClose={() => setErrorMessage(null)}>
          {errorMessage}
        </LegacyDialog>
      ) : (
        <></>
      )}
    </div>
  )
})

function buildReturnUrl(token: string): string {
  const returnUrl = new URL(document.location.href)
  returnUrl.searchParams.set('validate', 'true')
  returnUrl.searchParams.set('token', token)
  return returnUrl.toString()
}
